import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'

const Post = props => {
  const { markdownRemark: post } = props.data

  return (
    <Layout {...props}>
      <div className="main-content content-module">
        <Container>
          <h1>{post.frontmatter.title}</h1>
        </Container>
        <Container textWidth>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Container>
      </div>
    </Layout>
  )
}

export default Post
export const pageQuery = graphql`
  query PostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`
